<template>
  <div class="insect-count-table">
    <data-table
      class="data-table"
      externally-sorted
      :headers="headers"
      :items="sortedInsects"
      :options.sync="options"
      hidePagination
      v-if="!hideInsectTypes"
    >
      <template #item="props">
        <td class="text-center">{{ props.item.symbol }}</td>
        <td>
          <div class="d-flex align-center justify-space-between">
            <span>{{props.item.label}}</span>
            <icon-button
              small
              icon="info"
              @click="showInsectInfo(props.item.type)"
              v-if="props.item.type !== 'others'"
            />
          </div>
        </td>
        <td class="text-center">{{ props.item.count }}</td>
        <td class="text-center" v-if="showRadiobutton">
          <icon-button
            small
            icon="fa-play"
            color="primary"
            @click="$emit('click:focus')"
            v-if="showFocusButton && selectedRadioButton === props.item.type"
          />
          <input
            type="radio"
            :name="radioGroupName"
            :value="props.item.type"
            v-model="selectedRadioButton"
            v-else
          >
        </td>
      </template>
      <template #body.append>
        <tr>
        <td class="text-right" colspan="2">{{$t('table.footer.total')}}</td>
        <td class="text-center">{{ totalInsectCount }}</td>
        <td class="text-center" v-if="showRadiobutton">
          <input
            type="radio"
            :name="radioGroupName"
            value="all"
            v-model="selectedRadioButton"
          >
        </td>
      </tr>
    </template>
    </data-table>
    <table class="no-types-table" v-else>
      <tbody>
        <tr>
          <td class="text-right" colspan="2">{{$t('table.footer.total')}}</td>
          <td class="text-center">{{ totalInsectCount }}</td>
        </tr>
      </tbody>
    </table>
    <card-dialog v-model="insectExplanationVisible" width="600px">
      <insect-description :insect-type="insectExplanationType" />
      <template #actions>
        <dialog-action @click="insectExplanationVisible = false">{{ $t('button.close') }}</dialog-action>
      </template>
    </card-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  table:
    header:
      symbol: '識別'
      insect_type: '虫種'
      count: 'カウント'
      radio: '描画'
    footer:
      total: '合計'

  button:
    close: '閉じる'

en:
  table:
    header:
      symbol: 'Symbol'
      insect_type: 'Type'
      count: 'Count'
      radio: 'Draw'
    footer:
      total: 'Total'

  button:
    close: 'Close'
</i18n>

<script>
import CardDialog from '@/components/atoms/CardDialog';
import DataTable from '@/components/atoms/DataTable';
import DialogAction from '@/components/atoms/DialogAction';
import IconButton from '@/components/atoms/IconButton';
import InsectDescription from '@/components/organisms/InsectDescription';
import InsectTypes from '@/mixins/insectTypes';

export default {
  name: 'insect-count-table',
  components: {
    CardDialog,
    DataTable,
    DialogAction,
    IconButton,
    InsectDescription,
  },
  mixins: [InsectTypes],
  props: {
    insectCounts: {
      type: Object,
      required: true,
    },
    hideInsectTypes: {
      type: Boolean,
      default: false,
    },
    dataVersion: { // For insect types mixin
      type: Number,
    },
    showFocusButton: {
      type: Boolean,
      default: false,
    },
    showRadiobutton: {
      type: Boolean,
      default: false,
    },
    pickedDetectionType: {
      type: String,
    },
  },
  computed: {
    insects() {
      // eslint-disable-next-line camelcase
      return this._.map(this.insectCounts, (count, type) => ({
        ...this.INSECT_TYPES[type],
        type,
        count,
      }));
    },
    totalInsectCount() {
      return this._.sumBy(this.insects, 'count') || 0;
    },
    headers() {
      return [].concat(
        [
          { text: this.$t('table.header.symbol'), align: 'center', value: 'symbol' },
          { text: this.$t('table.header.insect_type'), align: 'center', value: 'label' },
          { text: this.$t('table.header.count'), align: 'center', value: 'count' },
        ],
        this.showRadiobutton ? [
          { text: this.$t('table.header.radio'), align: 'center', sortable: false },
        ] : [],
      );
    },
    sortedInsects() {
      const other = this.insects.filter(x => x.type === 'others');
      const withoutOther = this.insects.filter(x => x.type !== 'others');
      return withoutOther.sort(this.sortInsects()).concat(other);
    },
  },
  methods: {
    showInsectInfo(insectType) {
      this.insectExplanationVisible = true;
      this.insectExplanationType = insectType;
    },
    sortInsects() {
      const sortby = this.options.sortBy[0];
      if (this.options.sortDesc[0]) {
        return ((a, b) => (a[sortby] < b[sortby] ? 1 : -1));
      }
      return ((a, b) => (a[sortby] > b[sortby] ? 1 : -1));
    },
  },
  data() {
    return {
      options: { sortBy: 'count', sortDesc: true, itemsPerPage: 30 },
       // 生成毎にname属性をつけないと、全てのradioボタンがグループ化される。
      radioGroupName: Math.random().toString(36).slice(-8),
      selectedRadioButton: this.pickedDetectionType,
      insectExplanationVisible: false,
      insectExplanationType: null,
    };
  },
  watch: {
    pickedDetectionType(value) {
      if (this.selectedRadioButton !== value) {
        this.selectedRadioButton = value;
      }
    },
    selectedRadioButton(v) {
      this.$emit('update:pickedDetectionType', v);
    },
  },
};
</script>

<style lang="sass" scoped>
.insect-count-table
  .data-table, ::v-deep .v-data-table
    height: 100%

  ::v-deep .v-data-table__wrapper
    height: 100%
    overflow-y: auto !important

  ::v-deep table
    thead
      position: sticky
      top: 0
      z-index: 1

    tbody tr:last-child td
      position: sticky
      bottom: 0
      background-color: white
      z-index: 1

  .no-types-table
    width: 100%
    border-spacing: 0

    ::v-deep td
      border: none
      height: 48px
      padding: 0 16px

    ::v-deep td + td
      border-left: lightgray solid 1px
</style>
