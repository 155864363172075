<template>
  <div>
    <div class="chart-row" v-for="(weekday, index) in weekLabels" :key="weekday">
      <div class="chart-label">{{weekday}}</div>
      <div class="chart-container">
        <hourly-bar-chart
          class="hourly-bar-chart"
          :event-data="eventDataOnWeekday(index)"
          :options="chartOptions(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HourlyBarChart from './HourlyBarChart';

export default {
  name: 'weekly-and-hourly-bar-chart',
  props: {
    eventData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    HourlyBarChart,
  },
  computed: {
    weekLabels() {
      const locale = this.$i18n.locale;
      const weekLabels = {
        ja: ['日', '月', '火', '水', '木', '金', '土'],
        en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      };
      return weekLabels[locale];
    },
    maxCounts() {
      return this.eventData.reduce((acc, { count }) => Math.max(acc, count), 0);
    },
    xAxesOnTop() {
      return {
        scales: {
          xAxis: {
            position: 'top',
          },
          yAxis: {
            suggestedMax: this.maxCounts,
          },
        },
      };
    },
    xAxesIsHidden() {
      return {
        scales: {
          xAxis: {
            ticks: {
              display: false,
            },
          },
          yAxis: {
            suggestedMax: this.maxCounts,
          },
        },
      };
    },
    xAxesOnBottom() {
      return {
        scales: {
          xAxis: {
            position: 'bottom',
          },
          yAxis: {
            suggestedMax: this.maxCounts,
          },
        },
      };
    },
  },
  methods: {
    eventDataOnWeekday(weekday) {
      return this.eventData.filter(({ timestamp }) => new Date(timestamp * 1000).getDay() === weekday);
    },
    chartOptions(weekday) {
      switch (weekday) {
        case 0:
          return this.xAxesOnTop;
        case 6:
          return this.xAxesOnBottom;
        default:
          return this.xAxesIsHidden;
      }
    },
  },
};
</script>

<style scoped lang="sass">
.chart-row
  display: flex
  align-items: center

  &:not(:last-child)
    border-bottom: 2px solid #e0e0e0

.chart-label
  display: flex
  align-items: center
  justify-content: center
  font-weight: 500
  font-size: 24px
  min-width: 100px
  height: 166px // 150px + 8px * 2
  border-right: 2px solid #e0e0e0

.chart-container
  flex: 1
  height: 150px
  margin: 8px 0 8px 16px

.hourly-bar-chart
  height: 100%
</style>
