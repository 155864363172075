var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "entity-list-layout",
    {
      staticClass: "admin-sensor-list",
      attrs: {
        title: "SENSOR",
        count: _vm.sensors.length,
        query: _vm.search,
        "search-hint": _vm.$t("search_by_text"),
      },
      on: {
        "update:query": function ($event) {
          _vm.search = $event
        },
      },
    },
    [
      _c(
        "p-data-list",
        {
          attrs: {
            pagination: _vm.pagination,
            data: _vm.sensors,
            getItemProps: _vm.genItemProps,
          },
          on: {
            "update:pagination": function ($event) {
              _vm.pagination = $event
            },
          },
        },
        [
          _c("data-field", {
            attrs: { name: "No", headerClass: "column--no" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.index + 1))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: "",
              value: "type",
              headerClass: "column--type",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c("sensor-icon", {
                          key: props.item.id,
                          attrs: { sensor: props.item, size: 36 },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.identifier"),
              value: "id",
              sortable: "",
              headerClass: "column--id",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "span",
                      { staticClass: "admin-sensor-list__sensor-id" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(props.item.id) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.name"),
              value: "name",
              sortable: "",
              headerClass: "column--name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "admin-sensor",
                            params: { id: props.item.id },
                          },
                          headerClass: "admin-sensor-list__sensor-name",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(props.item.name) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.installation_date"),
              value: "installed_on",
              sortable: "",
              headerClass: "column--installed-on",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.item.installedOnText))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.threshold"),
              value: "threshold",
              sortable: "",
              headerClass: "column--threshold",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c("div", { style: _vm._f("thresholdStyle")(props.item) }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(props.thresholdText) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.installation_site"),
              value: "place",
              sortable: "",
              headerClass: "column--place",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.item.place))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.organization_name"),
              value: "created_by.organization_name",
              headerClass: "column--organization",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "admin-organization",
                            params: {
                              id: props.item.created_by.organization_id,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(props.item.created_by.organization_name) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.created_by"),
              value: "created_by.name",
              headerClass: "column--name",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "admin-account",
                            params: { email: props.item.created_by.email },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(props.item.created_by.name) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }