<template>
  <div>
    <page-title-area>
      <h1>{{$t('title')}}</h1>
    </page-title-area>
    <v-tabs v-model="activeTabIndex">
      <v-tab>{{ $t('sum') }}</v-tab>
      <v-tab
        v-for="sensor in sensors"
        :key="sensor.id"
      >
        {{ sensor.name }}
      </v-tab>
    </v-tabs>
    <div class="number-of-sensors">
      <span class="primary--text" v-if="displayingSumTab">
        {{ $t('displayed_number_of_sensors', { count: sensors.length }) }}
      </span>
    </div>
    <v-card class="graph-area">
      <div class="d-flex">
        <h2>{{ $t('insect_count_vs_previous_month') }}</h2>
        <v-spacer />
        <alert-legend v-if="showAlertPeriods"/>
      </div>
      <v-row>
        <v-col cols="6">
          <daily-insect-count-chart
            class="count-graph"
            :event-data="selectedSensorData"
            :month="previousMonth"
            :title="$t('previous_month')"
            :y-max="dailyInsectCountYMax"
            :show-alert-periods="showAlertPeriods"
            @max-count-updated="value => updateMaxDailyInsectCount(0, value)"
          />
        </v-col>
        <v-col cols="6">
          <daily-insect-count-chart
            class="count-graph"
            :event-data="selectedSensorData"
            :month="currentMonth"
            :title="$t('current_month')"
            :y-max="dailyInsectCountYMax"
            :show-alert-periods="showAlertPeriods"
            @max-count-updated="value => updateMaxDailyInsectCount(1, value)"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card class="graph-area">
      <div class="d-flex">
        <h2>{{ $t('insect_count_vs_previous_year') }}</h2>
        <v-spacer />
        <alert-legend v-if="showAlertPeriods"/>
      </div>
      <v-row>
        <v-col cols="6">
          <daily-insect-count-chart
            class="count-graph"
            :event-data="selectedSensorData"
            :month="sameMonthPreviousYear"
            :title="$t('same_month_previous_year')"
            :y-max="dailyInsectCountYMax"
            :show-alert-periods="showAlertPeriods"
            @max-count-updated="value => updateMaxDailyInsectCount(2, value)"
          />
        </v-col>
        <v-col cols="6">
          <daily-insect-count-chart
            class="count-graph"
            :event-data="selectedSensorData"
            :month="currentMonth"
            :title="$t('current_month')"
            :y-max="dailyInsectCountYMax"
            :show-alert-periods="showAlertPeriods"
            @max-count-updated="value => updateMaxDailyInsectCount(3, value)"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="6">
        <v-card class="graph-area">
          <div>
            <h2>{{ $t('hourly_insect_count') }}</h2>
          </div>
          <hourly-bar-chart
            class="count-graph"
            :event-data="selectedSensorData"
          />
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="graph-area">
          <div>
            <h2>{{ $t('weekly_insect_count') }}</h2>
          </div>
          <weekly-bar-chart
            class="count-graph"
            :event-data="selectedSensorData"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-card class="graph-area">
      <div>
        <h2>{{ $t('weekly_and_hourly_insect_count') }}</h2>
      </div>
      <weekly-and-hourly-bar-chart
        :event-data="selectedSensorData"
      />
    </v-card>
  </div>
</template>

<i18n lang="yaml">
ja:
  current_month: 当月
  displayed_number_of_sensors: '対象センサー数: {count}台'
  insect_count_vs_previous_month: 対前月 捕獲数
  insect_count_vs_previous_year: 対昨年 捕獲数
  previous_month: 前月
  same_month_previous_year: 昨年同月
  hourly_insect_count: 時間帯別 捕獲数
  weekly_insect_count: 曜日別 捕獲数
  weekly_and_hourly_insect_count: 曜日 x 時間帯別 捕獲数
  sum: 合計
  title: モニタリングサマリー

en:
  current_month: Current Month
  displayed_number_of_sensors: 'Displaying {count} sensors'
  insect_count_vs_previous_month: Insect count compared with previous month
  insect_count_vs_previous_year: Insect count compared with previous year
  previous_month: Previous Month
  same_month_previous_year: Same Month Previous Year
  hourly_insect_count: Hourly Insect Count
  weekly_insect_count: Weekly Insect Count
  weekly_and_hourly_insect_count: Weekly x Hourly Insect Count
  sum: Sum
  title: Monitoring Summary
</i18n>

<script>
import { addToMonthString } from '@/libs/time';

import PageTitleArea from '@/components/atoms/PageTitleArea';
import DailyInsectCountChart from '@/components/organisms/DailyInsectCountChart';
import AlertLegend from './AlertLegend';
import HourlyBarChart from './HourlyBarChart';
import WeeklyBarChart from './WeeklyBarChart';
import WeeklyAndHourlyBarChart from './WeeklyAndHourlyBarChart';
import { getSensorDummyEvents } from './dummyData';

export default {
  name: 'dashboard',
  props: {
  },
  components: {
    AlertLegend,
    DailyInsectCountChart,
    HourlyBarChart,
    WeeklyBarChart,
    WeeklyAndHourlyBarChart,
    PageTitleArea,
  },
  data() {
    return {
      currentMonth: '2024-08',
      sensors: [],
      eventData: {},
      activeTabIndex: 0,
      maxDailyInsectCounts: [0, 0, 0, 0],
    };
  },
  mounted: async function () {
    this.sensors = await this.fetchSensors();
    this.sensors.forEach((sensor) => {
      this.fetchAndConvertEvents(sensor.id);
    });
  },
  computed: {
    displayingSumTab() {
      return this.activeTabIndex === 0;
    },
    dailyInsectCountYMax() {
      const totalMaxCount = Math.max(...this.maxDailyInsectCounts);

      let roundTo = 5;
      if (totalMaxCount >= 100) {
        roundTo = 20;
      }

      return this.roundToNext(totalMaxCount, roundTo);
    },
    sameMonthPreviousYear() {
      return addToMonthString(this.currentMonth, -12);
    },
    previousMonth() {
      return addToMonthString(this.currentMonth, -1);
    },
    selectedSensorData() {
      if (this.displayingSumTab) {
        return Object.values(this.eventData).flatten();
      }

      const selectedSensor = this.sensors[this.activeTabIndex - 1];

      return this.eventData[selectedSensor.id];
    },
    showAlertPeriods() {
      return !this.displayingSumTab;
    },
  },
  methods: {
    convertEvent(event) {
      return {
        timestamp: event.timestamp,
        count: event.count,
        countDifference: event.count_difference,
        alert: event.count > event.sensor_threshold,
        increaseAlert: event.count_difference > event.sensor_increase_threshold,
        sensorId: event.sensor_id,
      };
    },
    fetchSensors: async function () {
      return [
        { id: 's1', name: 'センサー１' },
        { id: 's2', name: 'センサー２' },
        { id: 's3', name: 'センサー３' },
      ];
    },
    fetchAndConvertEvents: async function (sensorId) {
      const events = getSensorDummyEvents(sensorId);

      this.eventData = {
        ...this.eventData,
        [sensorId]: events.map(event => this.convertEvent(event)),
      };
    },
    roundToNext(value, increment) {
      return Math.ceil(value / increment) * increment;
    },
    updateMaxDailyInsectCount(graphIndex, updatedValue) {
      const newValue = [...this.maxDailyInsectCounts];
      newValue[graphIndex] = updatedValue;
      this.maxDailyInsectCounts = newValue;
    },
  },
};
</script>

<style scoped lang="sass">
.number-of-sensors
  height: 3rem
  padding-top: 16px

  font-size: 1.5rem
  font-weight: bold

.graph-area
  padding-left: 16px
  padding-right: 16px
  padding-bottom: 16px
  margin-top: 16px
  margin-bottom: 16px

  h2
    font-size: 1.25rem
    font-weight: normal
    padding: 16px

.count-graph
  height: 300px
</style>
