<template>
  <base-dialog
    :content-class="`overflow-x-hidden ${contentClass}`"
    fullscreen
    hide-overlay
    :value="visible"
    @input="val => $emit('visibilityChanged', val)"
  >
    <v-card class="gallery-view gallery-view--insect editing">
      <v-toolbar class="gallery-view-toolbar" height="auto">
        <v-toolbar-title class="d-flex align-center">
          <slot name="header" />
        </v-toolbar-title>
        <v-toolbar-items>
          <select-field
            v-model="defaultInsectType"
            :items="INSECT_TYPES_FOR_OPTION"
            class="gallery-view__actions__select-field"
            itemMenuClass="insect-detections-type-select"
            edit
            v-if="canViewDetectionDetail && model.editMode === 'add'"
          />
          <v-divider vertical light />
          <toolbar-button
            :highlight="model.editMode == 'add'"
            icon="fa-square-o"
            @click.native.stop="model.editMode = 'add'">
            {{ $t('menu.add') }}
          </toolbar-button>
          <toolbar-button
            :highlight="model.editMode == 'pick'"
            icon="fa-mouse-pointer"
            @click.native.stop="model.editMode = 'pick'">
            {{ $t('menu.pick') }}
          </toolbar-button>
          <toolbar-button
            :highlight="model.editMode == 'control'"
            icon="control_camera"
            @click.native.stop="model.editMode = 'control'">
            {{ $t('menu.control') }}
          </toolbar-button>
          <v-divider vertical light />
          <toolbar-button
            icon="fa-save"
            :disabled="!detectionsChanged"
            @click.native.stop="updateCurrentEventDetections">
            {{ $t('menu.save') }}
          </toolbar-button>
          <toolbar-button icon="fa-close" @click.native.stop="cancel">
            {{ $t('menu.cancel') }}
          </toolbar-button>
        </v-toolbar-items>
      </v-toolbar>
      <gallery-view-layout>
        <template #top>
          <div class="sub-toolbar-wrapper">
          </div>
        </template>
        <div class="gallery-view__content">
          <div class="gallery-view__content__main">
            <detections-editor
              v-model="model.detections"
              :imageSrc="imageSrc"
              :editMode="model.editMode"
              :options="editorOptions"
              :key="visible"
            />
          </div>
          <div class="gallery-view__content__side">
            <insect-count-table
              :dataVersion="dataVersion"
              :insectCounts="insectCounts"
              :hideInsectTypes="!canViewDetectionDetail"
            />
          </div>
        </div>
      </gallery-view-layout>
      <confirm-dialog
        v-model="saveBeforeCancelDialogVisible"
        :message="$t('save_before_cancel')"
        @yes="updateCurrentEventDetections"
        @no="close"
      />
    </v-card>
  </base-dialog>
</template>

<i18n lang="yaml">
ja:
  save_before_cancel: 編集内容を保存しますか？

  menu:
    add: 追加
    cancel: キャンセル
    pick: 選択
    control: 操作
    save: 保存する

en:
  save_before_cancel: Do you want to save your changes?

  menu:
    add: Add
    cancel: Cancel
    pick: Pick
    control: 'Pan & Zoom'
    save: Save
</i18n>

<script>
import InsectTypes from '@/mixins/insectTypes';

import BaseDialog from '@/components/atoms/BaseDialog';
import ConfirmDialog from '@/components/atoms/ConfirmDialog';
import ToolbarButton from '@/components/atoms/ToolbarButton';
import SelectField from '@/components/Form/SelectField';
import InsectCountTable from '@/components/InsectCountTable';
import GalleryViewLayout from '@/components/GalleryView/Layout';
import DetectionsEditor from './DetectionsEditor';

export default {
  name: 'insect-detections-editor',
  mixins: [InsectTypes],
  props: {
    event: Object,
    detectionsUrl: String,
    imageSrc: String,
    visible: Boolean,
    canViewDetectionDetail: Boolean,
    originalImageWidth: Number,
    originalImageHeight: Number,
    contentClass: String,
  },
  components: {
    BaseDialog,
    ConfirmDialog,
    GalleryViewLayout,
    ToolbarButton,
    InsectCountTable,
    DetectionsEditor,
    SelectField,
  },
  computed: {
    editorOptions() {
      return {
        defaultInsectType: this.defaultInsectType,
        withInsectTypes: this.canViewDetectionDetail,
        originalImageWidth: this.originalImageWidth,
        originalImageHeight: this.originalImageHeight,
      };
    },
    dataVersion() { // Sets INSECT_TYPES from insectTypes mixin
      return this.event.insect_types_version;
    },
    insectCounts() {
      const detections = this._.reject(this.model.detections, { remove: true });
      if (!this.canViewDetectionDetail) {
        return { other: detections.length };
      }

      const result = {};
      detections.forEach((detection) => {
        result[detection.type] = (result[detection.type] || 0) + 1;
      });
      return result;
    },
    detectionsChanged() {
      return this.model.detections.some((detection) => {
        const originalDetection = this.findOriginalDetection(detection);
        return !originalDetection ||
          detection.type !== originalDetection.type ||
          detection.remove;
      });
    },
  },
  data() {
    return {
      saveBeforeCancelDialogVisible: false,
      defaultInsectType: '',
      originalDetections: [],
      model: {
        detections: [],
        editMode: 'control',
      },
    };
  },
  methods: {
    cancel() {
      if (this.detectionsChanged) {
        this.saveBeforeCancelDialogVisible = true;
      } else {
        this.close();
      }
    },
    close() {
      this.$emit('visibilityChanged', false);
    },
    findOriginalDetection(detection) {
      return this.originalDetections[detection.index];
    },
    initializeEditing: async function () {
      const response = await this.$http.get(this.detectionsUrl);
      this.originalDetections = response.data.positions;
      this.model.detections = this.originalDetections.map((item, index) => ({
        index,
        ...item,
        ...this.INSECT_TYPES[item.type],
      }));
    },
    updateCurrentEventDetections: async function () {
      const _ = this._;
      const added = _.filter(this.model.detections, { added: true });
      const modified = this.originalDetections.map(
        (orig, index) => _.find(this.model.detections, { index: index }) || orig,
      );
      let newDetections = _.reject(modified.concat(added), { remove: true });
      newDetections = _.map(newDetections, item => _.pick(item, ['type', 'position']));
      await this.$http.put(this.detectionsUrl, {
        positions: newDetections,
      });
      this.originalDetections = newDetections;
      this.$emit('updated');
      this.close();
    },
    setZoom() {
    },
  },
  provide() {
    return {
      setZoom: this.setZoom,
    };
  },
  model: {
    prop: 'visible',
    event: 'visibilityChanged',
  },
  watch: {
    visible(value) {
      this.originalDetections = [];
      this.model.editMode = 'control';
      if (value) {
        this.defaultInsectType = this._.head(Object.keys(this.INSECT_TYPES));
        this.initializeEditing();
      }
    },
  },
};
</script>

<style lang="sass">
.insect-detections-type-select
  z-index: 50 !important
</style>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.gallery-view
  &__actions
    display: flex

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      display: none

    &__select-field
      display: flex
      align-items: center
      margin-right: 0.5rem
      white-space: nowrap

      ::v-deep .v-input
        height: 100%
        align-items: stretch

        &__control
          justify-content: center

        .v-select__selection
          max-width: none

  &__content
    padding: 12px

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      flex-direction: column

    @media #{map-get($display-breakpoints, 'md-and-up')}
      ::v-deep .loading-image
        min-height: 300px

      &__main
        margin-right: 12px

      &__side
        min-width: 300px
        max-width: 300px

  &--insect.editing
    height: 100vh

    .gallery-view__content__main
      &::before
        content: none

      &__inner
        position: static

  .sub-toolbar-wrapper
    @media #{map-get($display-breakpoints, 'md-and-up')}
      height: 48px

.v-card
  background-color: map-get($material-theme, 'background') !important

::v-deep .sub-toolbar
  padding-left: calc(#{$spacer} * 3)

  @media #{map-get($display-breakpoints, 'md-and-up')}
    padding-left: calc(#{$spacer} * 5)

::v-deep .gallery-view
  &__content
    display: flex
    flex-direction: column
    justify-content: center

    &__main
      min-width: 1px

      .insect-view-detections-editor
        height: calc(100vh - 200px)
        overflow: hidden
        text-align: center
        font-size: 0

    &__side
      margin-top: 24px
      margin-bottom: 24px
      height: calc(100vh - 200px)

      .insect-count-table
        height: 100%

        .v-data-table,
        .v-data-table__wrapper
          height: 100%

        .v-data-table__wrapper
          overflow-y: auto

  @media #{map-get($display-breakpoints, 'md-and-up')}
    &__content
      flex-direction: row

      &__main
        flex-grow: 2

      &__side
        margin: 0 0 0 16px

$header-height: 56px
$header-padding-x: $spacer * 5
$header-padding-y: $spacer

.gallery-view-toolbar
  ::v-deep .v-toolbar
    &__content
      display: flex
      flex-direction: column
      height: auto

    &__title
      height: 72px
      margin: 0 !important
      padding: $header-padding-y $header-padding-x
      width: 100%

    &__items
      height: $header-height
      margin: 0 !important
      padding: $header-padding-y 0

  ::v-deep .content-header
    color: map-get($blue, 'darken-2')
    margin: 0

  .divider
    background-color: #ccc
    height: 2px
    margin: 0 !important
    width: 100vw

@media #{map-get($display-breakpoints, 'md-and-up')}
  .gallery-view-toolbar
    padding: ($header-padding-y * 2) $header-padding-x

    ::v-deep .v-toolbar
      &__content
        flex-direction: row

      &__title
        height: $header-height
        padding: 0

    .details
      padding-left: 3rem
      padding-right: 3rem
</style>
