<template>
  <div class="pv-select-field">
    <select-box
      :items="items"
      :rules="rules"
      :value="value"
      @input="v => $emit('input', v)"
      :data-testid="dataTestid"
      ref="select"
      v-bind="$attrs"
      v-if="edit"
    />
    <span v-else>{{ selected.text }}</span>
  </div>
</template>

<script>
import SelectBox from '@/components/atoms/SelectBox';

export default {
  name: 'pv-select-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    rules: Array,
    value: {
      default: '',
    },
    'data-testid': String,
  },
  components: {
    SelectBox,
  },
  computed: {
    selected() {
      if (this.returnObject) {
        return this.value;
      }
      return this.items.find(i => i.value === this.value) || { text: '' };
    },
  },
  methods: {
    validate() {
      this.$refs.select.validate();
    },
  },
};
</script>

<style scoped lang="sass">
.pv-select-field
  ::v-deep .v-input
    margin: -6px 0 -5px
    padding: 0

    &__control
      background-color: transparent
      border: 1px solid

    .v-select__selections
      padding: 0 4px
</style>
