<template>
  <bar-chart :data="chartData" :options="options" />
</template>

<script>
import theme from '@/libs/theme';
import BarChart from '@/components/atoms/BarChart';

export default {
  name: 'weekly-bar-chart',
  props: {
    eventData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    BarChart,
  },
  data() {
    return {
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          yAxis: {
            beginAtZero: true,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: theme.primaryColor,
            data: this.chartValues,
          },
        ],
      };
    },
    chartValues() {
      return this.labels.map((_hour, index) => this.weeklyInsectCounts[index]);
    },
    weeklyInsectCounts() {
      return this.eventData.reduce((acc, { timestamp, count, countDifference }) => {
        const weekday = new Date(timestamp * 1000).getDay();
        if (countDifference < 0) {
          acc[weekday] = (acc[weekday] || 0) + count;
        } else {
          acc[weekday] = (acc[weekday] || 0) + countDifference;
        }
        return acc;
      }, {});
    },
    labels() {
      const LABELS = {
        ja: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      };
      return LABELS[this.$i18n.locale];
    },
  },
};
</script>

<style scoped lang="sass">
</style>
