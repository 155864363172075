<template>
  <div>
    <v-pagination
      :length="pages"
      :value="options.page"
      color="grey"
      @input="changePage"
      v-if="!hidePagination"
    />
    <v-data-table
      :headers="headersWithDefault"
      :items="items"
      :page="options.page"
      :sortBy="options.sortBy"
      :sortDesc="options.sortDesc"
      :itemsPerPage="options.itemsPerPage"
      @update:options="value => $emit('update:options', value)"
      hide-default-footer
      :custom-sort="sortItems"
      :value="value"
      @input="value => $emit('input', value)"
      @toggle-select-all="values => $emit('toggle-select-all', values)"
      :item-key="itemKey"
      :show-select="checkbox"
      multi-sort
      v-bind="$attrs"
    >
      <template #item="props" v-if="$scopedSlots.item">
        <tr>
          <td v-if="checkbox">
            <div class="pv-checkbox" v-if="selectableCheck(props.item)">
              <v-checkbox :input-value="props.isSelected" @change="value => props.select(value)" />
            </div>
          </td>
          <slot name="item" :item="props.item"/>
        </tr>
      </template>
      <template v-slot:[slot]="props" v-for="slot in nonItemSlots">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-data-table>
    <v-pagination
      :length="pages"
      :value="options.page"
      color="grey"
      @input="changePage"
      v-if="!hidePagination"
    />
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {
          page: 1,
          itemsPerPage: 20,
        };
      },
    },
    externallySorted: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    selectableCheck: {
      type: Function,
      default() {
        return true;
      },
    },
  },
  computed: {
    allSelected() {
      return this.value.length === this.selectableItems.length;
    },
    headersWithDefault() {
      return this.headers.map(header => ({ align: 'left', ...header }));
    },
    pages() {
      return Math.ceil(this.items.length / this.options.itemsPerPage);
    },
    selectableItems() {
      return this.items.filter(this.selectableCheck);
    },
    sortedItems() {
      return this.sortItems(this.items, this.options.sortBy, this.options.sortDesc);
    },
    nonItemSlots() {
      // TODO: Remove this and replace all item slots with `item.${columnName}` slots
      return Object.keys(this.$scopedSlots).filter(slotName => slotName !== 'item');
    },
  },
  methods: {
    changePage(newPage) {
      this.$emit('update:options', { ...this.options, page: newPage });
    },
    sortItems(items, sortBy, sortDesc) {
      if (this.externallySorted) {
        return items;
      }

      return this._.orderBy(items, sortBy, sortDesc.map(desc => (desc ? 'desc' : 'asc')));
    },
  },
  watch: {
    selectableItems(value) {
      // itemsが更新されても、valueに反映されないので、更新したitemsからvalueを再構成する
      const selectedKeys = this.value.map(item => item[this.itemKey]);
      const updatedSelectedItems =
        value.filter(item => selectedKeys.includes(item[this.itemKey]));
      this.$emit('input', updatedSelectedItems);
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

::v-deep .v-data-table
  th[role="columnheader"]
    color: white !important
    background-color: map-get($colors, 'grey', 'base')

    i,
    &.active,
    &:hover
      color: white !important

    &[aria-sort="none"] i
      display: none

  tbody
    tr:nth-child(odd):hover td,
    tr:nth-child(even):hover td
      background-color: map-get($colors, 'grey', 'lighten-2')

    tr:nth-child(odd) td
      background-color: map-get($colors, 'grey', 'lighten-3')

  th + th,
  td + td
    border-left: darken(map-get($colors, 'grey', 'lighten-2'), 5%) solid 1px

  td
    padding: 0 24px

  th
    color: white !important

::v-deep .input-group
  color: #FFF !important
  height: 21px
  margin-top: -4px
  padding: 0

.pv-checkbox
  ::v-deep .v-icon
    color: inherit !important
    font-size: 20px

  ::v-deep .input-group
    color: #666 !important
    height: 21px
    margin-top: -4px
    padding: 0

    .input-group__input
      color: inherit !important

    .input-group--selection-controls__ripple:before
      background-color: transparent !important
</style>
