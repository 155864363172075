<template>
  <entity-list-layout
    class="admin-sensor-list"
    title="SENSOR"
    :count="sensors.length"
    :query.sync="search"
    :search-hint="$t('search_by_text')"
  >
    <p-data-list :pagination.sync="pagination" :data="sensors" :getItemProps="genItemProps">
      <data-field name="No" headerClass="column--no">
        <template v-slot="props">{{ props.index + 1 }}</template>
      </data-field>
      <data-field name="" value="type" headerClass="column--type" sortable>
        <template v-slot="props">
          <div style="text-align: center;"><sensor-icon :key="props.item.id" :sensor="props.item" :size="36" /></div>
        </template>
      </data-field>
      <data-field :name="$t('field.identifier')" value="id" sortable headerClass="column--id">
        <template v-slot="props">
          <span class="admin-sensor-list__sensor-id">
            {{ props.item.id }}
          </span>
        </template>
      </data-field>
      <data-field :name="$t('field.name')" value="name" sortable headerClass="column--name">
        <template v-slot="props">
          <router-link :to="{ name: 'admin-sensor', params: { id: props.item.id }}" headerClass="admin-sensor-list__sensor-name">
            {{ props.item.name }}
          </router-link>
        </template>
      </data-field>
      <data-field :name="$t('field.installation_date')" value="installed_on" sortable headerClass="column--installed-on">
        <template v-slot="props">{{ props.item.installedOnText }}</template>
      </data-field>
      <data-field :name="$t('field.threshold')" value="threshold" sortable headerClass="column--threshold">
        <template v-slot="props">
          <div :style="props.item | thresholdStyle">
            {{ props.thresholdText }}
          </div>
        </template>
      </data-field>
      <data-field :name="$t('field.installation_site')" value="place" sortable headerClass="column--place">
        <template v-slot="props">{{ props.item.place }}</template>
      </data-field>
      <data-field :name="$t('field.organization_name')" value="created_by.organization_name" headerClass="column--organization" sortable>
        <template v-slot="props">
          <router-link :to="{ name: 'admin-organization', params: { id: props.item.created_by.organization_id }}">
            {{ props.item.created_by.organization_name }}
          </router-link>
        </template>
      </data-field>
      <data-field :name="$t('field.created_by')" value="created_by.name" headerClass="column--name" sortable>
        <template v-slot="props">
          <router-link :to="{ name: 'admin-account', params: { email: props.item.created_by.email }}">
            {{ props.item.created_by.name }}
          </router-link>
        </template>
      </data-field>
    </p-data-list>
  </entity-list-layout>
</template>

<i18n lang="yaml">
ja:
  search_by_text: '文字列で検索'
  field:
    identifier: '識別子'
    name: 'センサー名'
    installation_site: '設置場所'
    installation_date: '設置日'
    threshold: 'しきい値'
    organization_name: '組織名'
    created_by: '作成者'

en:
  search_by_text: 'Search by text'
  field:
    identifier: 'Identifier'
    name: 'Sensor name'
    installation_site: 'Installation site'
    installation_date: 'Installation date'
    threshold: 'Threshold'
    organization_name: 'Organization name'
    created_by: 'Created by'
</i18n>

<script>
import EntityListLayout from '@/components/atoms/EntityListLayout';
import DataList, { Field } from '../DataList';
import SensorIcon from '../SensorIcon';

const thresholdToString = (threshold) => {
  if (['null', 'undefined'].includes(threshold)) {
    return '-';
  }
  return String(threshold);
};

export default {
  name: 'admin-sensor-list',
  components: {
    DataField: Field,
    EntityListLayout,
    PDataList: DataList,
    SensorIcon,
  },
  beforeCreate() {
    this.$store.dispatch('requestSensors');
  },
  computed: {
    sensors() {
      let sensors = this.$store.getters.getSensors;
      sensors = sensors.map((sensor) => ({
        ...sensor,
        threshold: String(sensor.threshold),
        thresholdText: thresholdToString(String(sensor.threshold)),
        installedOnText: this.$moment(sensor.installed_on).format('YYYY/MM/DD'),
      }));
      sensors = this.filterSensors(sensors);
      sensors = this.sortSensors(sensors);
      return sensors;
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 20,
      },
      search: '',
    };
  },
  filters: {
    thresholdStyle(sensor) {
      if (['null', 'undefined'].includes(sensor.threshold)) {
        return 'text-align: center;';
      }
      return 'text-align: right;';
    },
  },
  methods: {
    filterSensors(sensors) {
      const lowerSearchText = this.search.toLowerCase();
      return sensors.filter((sensor) => {
        const { name: userName } = sensor.created_by;
        let { organization_name: organizationName } = sensor.created_by;
        organizationName = organizationName || '';

        const keywords = lowerSearchText.split(' ');
        // eslint-disable-next-line arrow-body-style
        return this._.every(keywords, (keyword) => {
          return this._.some([
            sensor.id,
            sensor.name,
            sensor.place,
            sensor.installedOnText,
            sensor.thresholdText,
            userName,
            organizationName,
          ], (value) => {
            const lowerValue = value.toLowerCase();
            return this._.includes(lowerValue, keyword);
          });
        });
      });
    },
    getUser(email) {
      return this.$store.getters.getUser(email);
    },
    genItemProps(item, props) {
      if (item.no_response) {
        return {
          ...props,
          class: `${props.class || ''} sensor--warning`,
        };
      }
      return props;
    },
    sortSensors(sensors) {
      let result = this._.orderBy(sensors, 'id');
      switch (this.pagination.sortBy) {
        case undefined:
          result = this._.orderBy(result, 'created_by.name');
          result = this._.orderBy(result, 'created_by.organization_name');
          return result;
        default:
          return result;
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$warning-color: #edcea9

.admin-sensor-list
  ::v-deep .data-list
    &--list .data-list
      &__item
        margin-left: -24px
        margin-right: -24px
        padding: 24px 24px 24px 72px
        position: relative

        &:not(:last-child)
          border-bottom: 0
          margin-bottom: 1px

          &:after
            background-color: #fff
            bottom: -1px
            content: ''
            height: 1px
            left: 24px
            position: absolute
            right: 24px
            z-index: 1

        .v-icon
          left: 24px
          position: absolute
          top: 24px

      &__field:second-child
        height: 0

    &__item.sensor--warning
      background-color: $warning-color

    &--table .data-list
      &__value--right
        text-align: right

  &__sensor-id,
  &__sensor-name
    word-break: break-word


  ::v-deep .column
    &--no
      width: 48px

    &--type
      width: 70px

    &--id,
    &--name
      min-width: 180px

    &--name
      width: 100%

    &--installed-on
      min-width: 130px

    &--threshold
      min-width: 100px

    &--place,
    &--organization,
    &--user
      width: 180px
      min-width: 140px
</style>
