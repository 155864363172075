<template>
  <entity-list-layout
    class="sensor-list"
    title="SENSOR"
    :count="sensors.length"
    :query.sync="search"
    :search-hint="$t('search')"
  >
    <template #title-area-content>
      <create-sensor-button v-if="creatable" />
    </template>
    <p-data-list :pagination.sync="pagination" :data="sensors" :getItemProps="genItemProps">
      <data-field name="No" headerClass="column--no">
        <template v-slot="props">{{ props.index + 1 }}</template>
      </data-field>
      <data-field :name="$t('field.type')" value="type_index" headerClass="column--type" sortable>
        <template v-slot="props">
          <sensor-type-label :sensor="props.item" />
        </template>
      </data-field>
      <data-field :name="$t('field.identifier')" value="id" headerClass="column--id" sortable>
        <template v-slot="props">
          <span class="sensor-list__sensor-id">
            {{ props.item.id }}
          </span>
        </template>
      </data-field>
      <data-field :name="$t('field.name')" value="name" headerClass="column--name" sortable>
        <template v-slot="props">
          <router-link class="sensor-list__sensor-name" :to="{ name: 'sensor', params: { id: props.item.id }}">
            {{ props.item.name }}
          </router-link>
        </template>
      </data-field>
      <data-field :name="$t('field.installation_date')" value="installed_on" headerClass="column--installed-on" sortable>
        <template v-slot="props">{{ props.item.installed_on | moment('YYYY/MM/DD')  }}</template>
      </data-field>
      <data-field :name="$t('field.threshold')" value="threshold" headerClass="column--threshold" sortable>
        <template v-slot="props">
          <div :style="props.item | thresholdStyle">
            {{ props.item | threshold }}
          </div>
        </template>
      </data-field>
      <data-field :name="$t('field.installation_site')" value="place" headerClass="column--place" sortable>
        <template v-slot="props">{{ props.item.place }}</template>
      </data-field>
      <data-field :name="$t('field.organization_name')" value="created_by.organization_name" headerClass="column--organization" sortable>
        <template v-slot="props">{{ props.item.created_by.organization_name }}</template>
      </data-field>
      <data-field :name="$t('field.created_by')" value="created_by.name" headerClass="column--user" sortable>
        <template v-slot="props">{{ props.item.created_by.name }}</template>
      </data-field>
    </p-data-list>
  </entity-list-layout>
</template>

<i18n lang="yaml">
ja:
  field:
    identifier: '識別子'
    name: 'センサー名'
    type: '種別'
    installation_site: '設置場所'
    installation_date: '設置日'
    threshold: 'しきい値'
    organization_name: '組織名'
    created_by: '作成者'
  search: '設置箇所で絞り込む'

en:
  field:
    identifier: 'Identifier'
    name: 'Sensor name'
    type: 'Type'
    installation_site: 'Installation site'
    installation_date: 'Installation date'
    threshold: 'Threshold'
    organization_name: 'Organization Name'
    created_by: 'Created by'
  search: 'Filter by site'
</i18n>

<script>
import { SENSOR_TYPES } from '@/mixins/sensorTypes';

import EntityListLayout from '@/components/atoms/EntityListLayout';
import SensorTypeLabel from '@/components/atoms/SensorTypeLabel';
import DataList, { Field } from '../DataList';
import CreateSensorButton from './CreateSensorButton';

const SENSOR_TYPE_VALUES = Object.values(SENSOR_TYPES);
export default {
  name: 'sensor-list',
  components: {
    CreateSensorButton,
    DataField: Field,
    EntityListLayout,
    PDataList: DataList,
    SensorTypeLabel,
  },
  beforeMount() {
    this.$store.dispatch('requestSensors');
  },
  computed: {
    sensors() {
      let sensors = this.$store.getters.getSensors.map((sensor) => ({
        ...sensor,
        threshold: String(sensor.threshold),
        type_index: this._.findIndex(SENSOR_TYPE_VALUES, (type) => type === sensor.type),
      }));
      sensors = this._.sortBy(sensors, 'id');
      const lowerSearchText = this.search.toLowerCase();
      return sensors.filter((sensor) => {
        const lowerSensorPlace = sensor.place.toLowerCase();
        return this._.includes(lowerSensorPlace, lowerSearchText);
      });
    },
    creatable() {
      const excepts = ['station', 'mirakun_device'];
      const { allowed_sensor_types } = this.$store.getters.getCurrentUser;
      const availableSensorTypes = this._.without(allowed_sensor_types, ...excepts);
      return availableSensorTypes.length > 0;
    },
  },
  data: function () {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 20,
        sortBy: 'type_index',
      },
      search: '',
    };
  },
  methods: {
    genItemProps(item, props) {
      if (item.no_response) {
        props.class = `${props.class || ''} sensor--warning`;
      }
      return props;
    },
  },
  filters: {
    threshold(sensor) {
      if (['null', 'undefined'].includes(sensor.threshold)) {
        return '-';
      }
      return sensor.threshold;
    },
    thresholdStyle(sensor) {
      if (['null', 'undefined'].includes(sensor.threshold)) {
        return 'text-align: center;';
      }
      return 'text-align: right;';
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.sensor-list
  &__sensor-id,
  &__sensor-name
    word-break: break-word

  ::v-deep .column
    &--no
      width: 48px

    &--type
      min-width: 180px
      max-width: 180px

    &--id,
    &--name
      min-width: 180px

    &--name
      width: 100%

    &--installed-on
      min-width: 130px

    &--threshold
      min-width: 100px

    &--place,
    &--organization,
    &--user
      width: 180px
      min-width: 140px
</style>
