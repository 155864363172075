<template>
  <v-select
    class='select-box'
    ref='select'
    :value="value"
    :rules="rules"
    @input="v => $emit('input', v)"
    v-bind="$attrs"
    :data-testid="dataTestid"
  />
</template>

<script>
export default {
  name: 'select-box',
  props: {
    value: {
      required: true,
    },
    rules: Array,
    itemMenuClass: String,
    'data-testid': String,
  },
  mounted() {
    if (this.itemMenuClass) {
      this.addItemMenuClass();
    }
  },
  methods: {
    addItemMenuClass() {
      // Overwrite computedContentClass computed of v-select
      // which defines the class of the menu overlay
      const originalClass = this.$refs.select.computedContentClass;
      const additionalClass = this.itemMenuClass;
      Object.defineProperty(this.$refs.select, 'computedContentClass', {
        get: function () {
          return `${originalClass} ${additionalClass}`;
        },
      });
    },
    blur() {
      this.$refs.select.blur();
    },
    validate() {
      this.$refs.select.validate();
    },
  },
};
</script>
<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.v-select
  ::v-deep .v-input__control
    border: 0 !important

  ::v-deep .v-select__slot
    border: 1px solid

    .v-label
      transform: translateY(-24px) scale(.75)

  ::v-deep .v-text-field__details,
  ::v-deep .v-messages
    min-height: 0
</style>
