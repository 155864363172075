<template>
  <div class="insect-count-difference">
    <span class="label">{{ $t('label') }}</span>
    <span class="mx-1">:</span>
    <span class="value">{{ difference | sign }}</span>
    <v-icon class="arrow" :class="{ increase: difference > 0, decrease: difference < 0 }">
      fa-long-arrow-right
    </v-icon>
  </div>
</template>

<i18n lang="yaml">
ja:
  label: 前データとの差

en:
  label: Difference from previous
</i18n>

<script>
export default {
  name: 'InsectCountDifference',
  props: {
    event: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    difference() {
      return this.event.count_difference;
    },
  },
  filters: {
    sign(value) {
      if (value > 0) {
        return `+ ${value}`;
      } else if (value < 0) {
        return `- ${Math.abs(value)}`;
      }
      return '± 0';
    },
  },
};
</script>

<style scoped lang="sass">
.insect-count-difference
  .arrow
    margin-left: 0.5rem
    transform: scaleX(0.8)
    color: green

    &.increase
      transform: rotateZ(-45deg) scaleX(0.8)
      color: red

    &.decrease
      transform: rotateZ(45deg) scaleX(0.8)
      color: blue
</style>
