var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-title-area", [_c("h1", [_vm._v(_vm._s(_vm.$t("title")))])]),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.activeTabIndex,
            callback: function ($$v) {
              _vm.activeTabIndex = $$v
            },
            expression: "activeTabIndex",
          },
        },
        [
          _c("v-tab", [_vm._v(_vm._s(_vm.$t("sum")))]),
          _vm._v(" "),
          _vm._l(_vm.sensors, function (sensor) {
            return _c("v-tab", { key: sensor.id }, [
              _vm._v("\n      " + _vm._s(sensor.name) + "\n    "),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "number-of-sensors" }, [
        _vm.displayingSumTab
          ? _c("span", { staticClass: "primary--text" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("displayed_number_of_sensors", {
                      count: _vm.sensors.length,
                    })
                  ) +
                  "\n    "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "graph-area" },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("insect_count_vs_previous_month"))),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.showAlertPeriods ? _c("alert-legend") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("daily-insect-count-chart", {
                    staticClass: "count-graph",
                    attrs: {
                      "event-data": _vm.selectedSensorData,
                      month: _vm.previousMonth,
                      title: _vm.$t("previous_month"),
                      "y-max": _vm.dailyInsectCountYMax,
                      "show-alert-periods": _vm.showAlertPeriods,
                    },
                    on: {
                      "max-count-updated": function (value) {
                        return _vm.updateMaxDailyInsectCount(0, value)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("daily-insect-count-chart", {
                    staticClass: "count-graph",
                    attrs: {
                      "event-data": _vm.selectedSensorData,
                      month: _vm.currentMonth,
                      title: _vm.$t("current_month"),
                      "y-max": _vm.dailyInsectCountYMax,
                      "show-alert-periods": _vm.showAlertPeriods,
                    },
                    on: {
                      "max-count-updated": function (value) {
                        return _vm.updateMaxDailyInsectCount(1, value)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "graph-area" },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("insect_count_vs_previous_year"))),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.showAlertPeriods ? _c("alert-legend") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("daily-insect-count-chart", {
                    staticClass: "count-graph",
                    attrs: {
                      "event-data": _vm.selectedSensorData,
                      month: _vm.sameMonthPreviousYear,
                      title: _vm.$t("same_month_previous_year"),
                      "y-max": _vm.dailyInsectCountYMax,
                      "show-alert-periods": _vm.showAlertPeriods,
                    },
                    on: {
                      "max-count-updated": function (value) {
                        return _vm.updateMaxDailyInsectCount(2, value)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("daily-insect-count-chart", {
                    staticClass: "count-graph",
                    attrs: {
                      "event-data": _vm.selectedSensorData,
                      month: _vm.currentMonth,
                      title: _vm.$t("current_month"),
                      "y-max": _vm.dailyInsectCountYMax,
                      "show-alert-periods": _vm.showAlertPeriods,
                    },
                    on: {
                      "max-count-updated": function (value) {
                        return _vm.updateMaxDailyInsectCount(3, value)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "graph-area" },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("hourly_insect_count")))]),
                  ]),
                  _vm._v(" "),
                  _c("hourly-bar-chart", {
                    staticClass: "count-graph",
                    attrs: { "event-data": _vm.selectedSensorData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "graph-area" },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("weekly_insect_count")))]),
                  ]),
                  _vm._v(" "),
                  _c("weekly-bar-chart", {
                    staticClass: "count-graph",
                    attrs: { "event-data": _vm.selectedSensorData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "graph-area" },
        [
          _c("div", [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("weekly_and_hourly_insect_count"))),
            ]),
          ]),
          _vm._v(" "),
          _c("weekly-and-hourly-bar-chart", {
            attrs: { "event-data": _vm.selectedSensorData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }