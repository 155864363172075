var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hidePagination
        ? _c("v-pagination", {
            attrs: {
              length: _vm.pages,
              value: _vm.options.page,
              color: "grey",
            },
            on: { input: _vm.changePage },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-data-table",
        _vm._b(
          {
            attrs: {
              headers: _vm.headersWithDefault,
              items: _vm.items,
              page: _vm.options.page,
              sortBy: _vm.options.sortBy,
              sortDesc: _vm.options.sortDesc,
              itemsPerPage: _vm.options.itemsPerPage,
              "hide-default-footer": "",
              "custom-sort": _vm.sortItems,
              value: _vm.value,
              "item-key": _vm.itemKey,
              "show-select": _vm.checkbox,
              "multi-sort": "",
            },
            on: {
              "update:options": function (value) {
                return _vm.$emit("update:options", value)
              },
              input: function (value) {
                return _vm.$emit("input", value)
              },
              "toggle-select-all": function (values) {
                return _vm.$emit("toggle-select-all", values)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm.$scopedSlots.item
                  ? {
                      key: "item",
                      fn: function (props) {
                        return [
                          _c(
                            "tr",
                            [
                              _vm.checkbox
                                ? _c("td", [
                                    _vm.selectableCheck(props.item)
                                      ? _c(
                                          "div",
                                          { staticClass: "pv-checkbox" },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                "input-value": props.isSelected,
                                              },
                                              on: {
                                                change: function (value) {
                                                  return props.select(value)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._t("item", null, { item: props.item }),
                            ],
                            2
                          ),
                        ]
                      },
                    }
                  : null,
                _vm._l(_vm.nonItemSlots, function (slot) {
                  return {
                    key: slot,
                    fn: function (props) {
                      return [_vm._t(slot, null, null, props)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          },
          "v-data-table",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      !_vm.hidePagination
        ? _c("v-pagination", {
            attrs: {
              length: _vm.pages,
              value: _vm.options.page,
              color: "grey",
            },
            on: { input: _vm.changePage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }