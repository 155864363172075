<template>
  <bar-chart :data="chartData" :options="chartOptions" />
</template>

<script>
import theme from '@/libs/theme';
import BarChart from '@/components/atoms/BarChart';

const LABELS = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
export default {
  name: 'hourly-bar-chart',
  props: {
    eventData: {
      type: Array,
      required: true,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BarChart,
  },
  computed: {
    chartData() {
      return {
        labels: LABELS,
        datasets: [
          {
            backgroundColor: theme.primaryColor,
            data: this.chartValues,
          },
        ],
      };
    },
    chartValues() {
      return LABELS.map((_hour, index) => this.hourlyInsectCounts[index]);
    },
    hourlyInsectCounts() {
      return this.eventData.reduce((acc, { timestamp, count, countDifference }) => {
        const hour = new Date(timestamp * 1000).getHours();
        if (countDifference < 0) {
          acc[hour] = (acc[hour] || 0) + count;
        } else {
          acc[hour] = (acc[hour] || 0) + countDifference;
        }
        return acc;
      }, {});
    },
    chartOptions() {
      return this._.merge({
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          yAxis: {
            beginAtZero: true,
          },
        },
      }, this.options);
    },
  },
};
</script>

<style scoped lang="sass">
</style>
