import moment from 'moment';

const createMonthlyDummyData = (beginDate, baseCount, threshold, increaseThreshold) => {
  const data = [];
  let count = baseCount;
  const currentDate = moment(beginDate);
  const endDate = moment(beginDate).add(1, 'month');
  while (currentDate.isBefore(endDate)) {
    const flag = Math.random();
    if (flag < 0.95) {
      count += 0;
    } else {
      count += Math.floor(Math.random() * 12);
    }
    data.push([
      currentDate.format('YYYY-MM-DD'),
      currentDate.format('HH:mm:ss'),
      Math.floor(count),
      threshold,
      increaseThreshold,
    ]);
    if ((count - increaseThreshold) > threshold) {
      count = 0;
    }
    currentDate.add(1, 'hour');
  }
  return data;
};

const DUMMY_DATA = {
  s1: [
    ...createMonthlyDummyData('2023-08-01', 0, 100, 10),
    ...createMonthlyDummyData('2024-07-01', 0, 100, 10),
    ...createMonthlyDummyData('2024-08-01', 0, 100, 10),
  ],
  s2: [
    ...createMonthlyDummyData('2023-08-01', 0, 100, 10),
    ...createMonthlyDummyData('2024-07-01', 0, 100, 10),
    ...createMonthlyDummyData('2024-08-01', 0, 100, 10),
  ],
  s3: [
    ...createMonthlyDummyData('2023-08-01', 0, 100, 10),
    ...createMonthlyDummyData('2024-07-01', 0, 100, 10),
    ...createMonthlyDummyData('2024-08-01', 0, 100, 10),
  ],
};

export const getSensorDummyEvents = (sensorId) => {
  let previousCount = 0;

  const result = [];

  DUMMY_DATA[sensorId].forEach((data) => {
    const [date, time, count, threshold, increaseThreshold] = data;
    const timestamp = moment(`${date}T${time}+09:00`).unix();

    // Event JSON
    result.push({
      timestamp,
      count,
      count_difference: count - previousCount,
      sensor_threshold: threshold,
      sensor_increase_threshold: increaseThreshold,
      sensor_id: sensorId,
    });

    previousCount = count;
  });

  return result;
};
