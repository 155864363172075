var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-title-area",
        {
          scopedSlots: _vm._u(
            [
              {
                key: "side-content",
                fn: function () {
                  return [
                    _vm.searchHint
                      ? _c("v-text-field", {
                          staticClass: "search-field py-0",
                          attrs: {
                            "prepend-icon": "search",
                            "hide-details": "",
                            placeholder: _vm.searchHint,
                            value: _vm.query,
                          },
                          on: {
                            input: function (value) {
                              return _vm.$emit("update:query", value)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("title-area-content"),
                  ]
                },
                proxy: true,
              },
              {
                key: "right-floating-content",
                fn: function () {
                  return [_c("counter", { attrs: { count: _vm.count } })]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [_c("h1", [_vm._v(_vm._s(_vm.title))])]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }