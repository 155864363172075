// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import moment from 'vue-moment';
import VueYoutube from 'vue-youtube';
import { sync } from 'vuex-router-sync';
import Vuetify from 'vuetify';
import lodash from 'lodash';
import VueLodash from 'vue-lodash/dist/vue-lodash.min';
import VueVideoPlayer from 'vue-video-player';
import VueSmoothScroll from 'vue2-smooth-scroll';
import VueResize from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
import http from './http';
import store from './store';
import router from './router';

Vue.config.productionTip = false;
Vue.use(http, { store });
Vue.use(moment);
Vue.use(VueYoutube);
Vue.use(Vuetify);
Vue.use(VueVideoPlayer);
Vue.use(VueLodash, lodash);
Vue.use(VueSmoothScroll);
Vue.use(VueResize);
sync(store, router);
