var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._g(
      { attrs: { icon: "", small: _vm.small, color: _vm.color } },
      _vm.$listeners
    ),
    [_c("v-icon", { attrs: { small: _vm.small } }, [_vm._v(_vm._s(_vm.icon))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }