<template>
  <div class="d-flex align-center">
    <div class="legend-box" :style="styles.totalCountAlert" />
    <span>{{ $t('total_count_alert') }}</span>
    <div class="legend-box" :style="styles.increaseCountAlert" />
    <span>{{ $t('increase_count_alert') }}</span>
  </div>
</template>

<i18n lang="yaml">
ja:
  total_count_alert: 総数アラート
  increase_count_alert: 増加数アラート

en:
  total_count_alert: Total Count Alert
  increase_count_alert: Increase Count Alert
</i18n>

<script>
import { ALERT_STYLES } from '@/components/organisms/DailyInsectCountChart';

export default {
  name: 'alert-legend',
  computed: {
    styles() {
      return ALERT_STYLES;
    },
  },
};
</script>

<style scoped lang="sass">
.legend-box
  margin-left: 10px
  margin-right: 5px
  width: 3rem
  height: 1rem
  border-style: solid
</style>
