var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        "content-class": "overflow-x-hidden " + _vm.contentClass,
        fullscreen: "",
        "hide-overlay": "",
        value: _vm.visible,
      },
      on: {
        input: function (val) {
          return _vm.$emit("visibilityChanged", val)
        },
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "gallery-view gallery-view--insect editing" },
        [
          _c(
            "v-toolbar",
            { staticClass: "gallery-view-toolbar", attrs: { height: "auto" } },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "d-flex align-center" },
                [_vm._t("header")],
                2
              ),
              _vm._v(" "),
              _c(
                "v-toolbar-items",
                [
                  _vm.canViewDetectionDetail && _vm.model.editMode === "add"
                    ? _c("select-field", {
                        staticClass: "gallery-view__actions__select-field",
                        attrs: {
                          items: _vm.INSECT_TYPES_FOR_OPTION,
                          itemMenuClass: "insect-detections-type-select",
                          edit: "",
                        },
                        model: {
                          value: _vm.defaultInsectType,
                          callback: function ($$v) {
                            _vm.defaultInsectType = $$v
                          },
                          expression: "defaultInsectType",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider", { attrs: { vertical: "", light: "" } }),
                  _vm._v(" "),
                  _c(
                    "toolbar-button",
                    {
                      attrs: {
                        highlight: _vm.model.editMode == "add",
                        icon: "fa-square-o",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.model.editMode = "add"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("menu.add")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "toolbar-button",
                    {
                      attrs: {
                        highlight: _vm.model.editMode == "pick",
                        icon: "fa-mouse-pointer",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.model.editMode = "pick"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("menu.pick")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "toolbar-button",
                    {
                      attrs: {
                        highlight: _vm.model.editMode == "control",
                        icon: "control_camera",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.model.editMode = "control"
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("menu.control")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider", { attrs: { vertical: "", light: "" } }),
                  _vm._v(" "),
                  _c(
                    "toolbar-button",
                    {
                      attrs: {
                        icon: "fa-save",
                        disabled: !_vm.detectionsChanged,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.updateCurrentEventDetections.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("menu.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "toolbar-button",
                    {
                      attrs: { icon: "fa-close" },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.cancel.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("menu.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "gallery-view-layout",
            {
              scopedSlots: _vm._u([
                {
                  key: "top",
                  fn: function () {
                    return [_c("div", { staticClass: "sub-toolbar-wrapper" })]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("div", { staticClass: "gallery-view__content" }, [
                _c(
                  "div",
                  { staticClass: "gallery-view__content__main" },
                  [
                    _c("detections-editor", {
                      key: _vm.visible,
                      attrs: {
                        imageSrc: _vm.imageSrc,
                        editMode: _vm.model.editMode,
                        options: _vm.editorOptions,
                      },
                      model: {
                        value: _vm.model.detections,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "detections", $$v)
                        },
                        expression: "model.detections",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gallery-view__content__side" },
                  [
                    _c("insect-count-table", {
                      attrs: {
                        dataVersion: _vm.dataVersion,
                        insectCounts: _vm.insectCounts,
                        hideInsectTypes: !_vm.canViewDetectionDetail,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("confirm-dialog", {
            attrs: { message: _vm.$t("save_before_cancel") },
            on: { yes: _vm.updateCurrentEventDetections, no: _vm.close },
            model: {
              value: _vm.saveBeforeCancelDialogVisible,
              callback: function ($$v) {
                _vm.saveBeforeCancelDialogVisible = $$v
              },
              expression: "saveBeforeCancelDialogVisible",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }