<template>
  <div class="station-event" v-if="event">
    <div class="d-flex align-baseline">
      <div class="headline font-weight-bold mr-4">
        <div class="d-flex align-center" v-if="event.installed_at && event.collected_at">
          <div class="mr-8">{{event.trap_no}}</div>
          <div>{{event.installed_at | moment($t('format.date'))}}</div>
          <div class="mx-2">~</div>
          <div>{{event.collected_at | moment($t('format.date'))}}</div>
        </div>
      </div>
      <div class="headline font-weight-bold flex">
        <div class="d-flex align-center" v-if="event.month">
          <div class="station-event__content align-center px-2">{{event.month | moment('YYYY-MM')}}</div>
          <div class="ml-2">{{$t('month')}}</div>
          <div class="station-event__edit-button" v-if="isNotRecentEvent" >
            <v-menu
              offset-y
              v-if="downloadOptions.length > 0"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>fa-download</v-icon>
                  <small>{{ $t('menu.download') }}</small>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="download(downloadOption.type)"
                  v-for="downloadOption in downloadOptions"
                >
                  {{ downloadOption.label }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              text
              @click="download"
              v-else
            >
              <v-icon>fa-download</v-icon>
              <small>{{ $t('menu.download') }}</small>
            </v-btn>
            <v-btn text :disabled="!hasDetections" @click.native.stop="editing = true;" v-if="!event.updating">
              <v-icon>fa-edit</v-icon>
              <small>{{ $t('menu.edit') }}</small>
            </v-btn>
            <v-btn text v-else disabled>
              <v-icon >fa-refresh</v-icon>
              <small>{{ $t('menu.updating') }}</small>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="pb-3">
        {{event.upload_time | dateTime($t('format.time'))}}
      </div>
    </div>
    <div>
      <div>
        <station-event-processing v-if="event.processing" />
        <div class="d-flex flex-nowrap" v-else>
          <div class="station-event__main">
            <v-carousel v-model="selectedIndex" hide-delimiters touchless height="auto" :key="event.id">
              <v-carousel-item v-for="photo in photos" :key="photo.id" eager>
                <div class="d-flex justify-center w-100">
                  <div class="station-event__drawer">
                    <picked-insects-draw
                      :imageSrc="photo | imageSrc"
                      :detectionsUrl="makeDetectionsUrl(photo)"
                      :pickedDetectionType="pickedDetectionType"
                      :originalImageWidth='ORIGINAL_IMAGE_WIDTH'
                      :originalImageHeight='ORIGINAL_IMAGE_HEIGHT'
                      :hideCanvas="viewOriginal"
                      @save="save"
                      ref="drawers"
                    />
                  </div>
                </div>
              </v-carousel-item>
            </v-carousel>
            <div class="station-event__selector">
              <dot-selector
                :value="selectedIndex"
                :size="photos.length"
                @change="selectPhoto"
              />
            </div>
          </div>
          <div class="station-event__side">
            <div class="station-event__table-area">
              <div class="d-flex align-center justify-end px-1">
                <v-checkbox
                  class="ma-0 pa-0 flex-grow-1"
                  style="flex-grow: 1"
                  v-model="viewOriginal"
                  :label="$t('original')"
                  color="primary"
                  hide-details
                />
                <v-btn text icon class="ma-2" @click="transView('table')" :disabled="currentViewIs('table')">
                  <v-icon :primary="currentViewIs('table')" x-large>fa-table</v-icon>
                </v-btn>
                <v-btn text icon class="ma-2" @click="transView('bar')" :disabled="currentViewIs('bar')">
                  <v-icon :primary="currentViewIs('bar')" x-large> fa-bar-chart</v-icon>
                </v-btn>
                <v-btn text icon class="ma-2" @click="transView('pie')" :disabled="currentViewIs('pie')">
                  <v-icon :primary="currentViewIs('pie')" x-large>fa-pie-chart</v-icon>
                </v-btn>
              </div>
              <insect-count-table
                v-if="currentViewIs('table')"
                class="station-event__result-table"
                :insectCounts="event.insect_counts"
                :showRadiobutton="!event.updating"
                :pickedDetectionType.sync="pickedDetectionType"
                showFocusButton
                @click:focus="handleFocus"
              />
              <insect-count-by-type-bar-chart
                :insectCounts="event.insect_counts"
                v-if="currentViewIs('bar')"
              />
              <top-5-insect-types-pie-chart
                :insectCounts="event.insect_counts"
                v-if="currentViewIs('pie')"
              />
            </div>
            <section data-testid="Metadata" class="pb-3 station-event__metadata">
              <ul>
                <li v-if="event.ip_address">
                  {{ $t('ip_address') }}: {{ event.ip_address }}
                </li>
                <li v-if="event.firmware_version">
                  {{ $t('firmware_version') }}: {{ event.firmware_version }}
                </li>
                <li v-if="event.serial_no">
                  {{ $t('serial_no') }}: {{ event.serial_no }}
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <insect-detections-editor
        :event="event"
        :imageSrc="current.display"
        :detectionsUrl="makeDetectionsUrl(current)"
        :originalImageWidth='ORIGINAL_IMAGE_WIDTH'
        :originalImageHeight='ORIGINAL_IMAGE_HEIGHT'
        canViewDetectionDetail
        v-model="editing"
        @updated="$emit('pollEvent')"
        v-if="isNotRecentEvent"
      >
        <template #header>
          <content-header class="w-100" :title="event.stationName" titleClass="title my-1">
            <!-- アイコン表示の為 fly_count を渡す-->
            <template #icon><icon type="fly_count" /></template>
            <template #subtitle>
              <div class="overflow-ellipsis title details">
                <span>{{ timestamp.date }}</span>
                <span class="ml-3">
                  {{ timestamp.time }}
                </span>
              </div>
            </template>
          </content-header>
        </template>
      </insect-detections-editor>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  title: '新着解析結果'
  count: '合計'
  month: '月分'
  original: 撮影画像を表示
  ip_address: ローカルIPアドレス
  firmware_version: ファームウェアのバージョン
  serial_no: S型
  format:
    date: 'YYYY年MM月DD日'
    time: 'YYYY年MM月DD日 HH:mm'
  menu:
    edit: '修正する'
    updating: '更新中'
    download: 'ダウンロード'
  msg:
    save:
      failed: 画像の保存に失敗しました。

en:
  title: 'Recent'
  count: 'Total'
  month: ' '
  original: Show original photo
  ip_address: Local IP Address
  firmware_version: Firmware Version
  serial_no: S-type
  format:
    date: 'YYYY-MM-DD'
    time: 'YYYY-MM-DD HH:mm'
  menu:
    edit: 'Edit'
    updating: 'Updating'
    download: 'Download'
  msg:
    save:
      failed: Failed to save image.
</i18n>

<script>
import moment from 'moment';

import Notifications from '@/mixins/notifications';
import TrainingDataDownload from '@/mixins/trainingDataDownload';

import InsectCountByTypeBarChart from '@/components/organisms/InsectCountByTypeBarChart';
import Top5InsectTypesPieChart from '@/components/organisms/Top5InsectTypesPieChart';
import ContentHeader from '@/components/ContentHeader';
import Icon from '@/components/Icon';
import InsectCountTable from '@/components/InsectCountTable';
import InsectDetectionsEditor from '@/components/InsectDetectionsEditor';
import PickedInsectsDraw from '@/components/PickedInsectsDraw';
import { ZoomProvider } from '@/components/Zoom';

import DotSelector from './DotSelector';
import StationEventProcessing from './StationEventProcessing';

const ORIGINAL_IMAGE_WIDTH = 50248;
const ORIGINAL_IMAGE_HEIGHT = 5472;

export default {
  name: 'station-v2-event',
  mixins: [
    Notifications,
    TrainingDataDownload,
    ZoomProvider,
  ],
  components: {
    ContentHeader,
    DotSelector,
    Icon,
    InsectCountByTypeBarChart,
    InsectCountTable,
    InsectDetectionsEditor,
    StationEventProcessing,
    PickedInsectsDraw,
    Top5InsectTypesPieChart,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    current() {
      return this._.get(this.photos, `[${this.selectedIndex}]`);
    },
    photos() {
      const { tab, ...photos } = this.event.attachments;
      const withKey = this._.map(photos, (value, key) => ({ key, ...value }));

      return [
        { key: 'tab', ...tab },
        ...this._.sortBy(withKey, ['key']),
      ];
    },
    isNotRecentEvent() {
      return !this.$route.path.match(/recent/);
    },
    hasDetections() {
      return this.current.key !== 'tab';
    },
    timestamp() {
      return {
        date: moment.unix(this.event.upload_time).format('YYYY.M.D'),
        time: moment.unix(this.event.upload_time).format('HH:mm'),
      };
    },
  },
  data() {
    return {
      selectedIndex: 1,
      currentView: 'table',
      pickedDetectionType: 'all',
      editing: false,
      viewOriginal: false,
      ORIGINAL_IMAGE_WIDTH,
      ORIGINAL_IMAGE_HEIGHT,
    };
  },
  inject: ['downloadAttachment'],
  methods: {
    handleFocus() {
      const drawer = this.$refs.drawers[this.selectedIndex];
      drawer.focusNext();
    },
    download: async function (type) {
      switch (type) {
        case 'trainingData': {
          this.downloadAttachment(this.event.id, this.current.key);
          break;
        }
        default: {
          const drawer = this.$refs.drawers[this.selectedIndex];
          this.save(drawer);
        }
      }
    },
    save: async function (canvas) {
      try {
        await canvas.export('download.png');
      } catch (error) {
        this.notifyError('msg.save.failed');
      }
    },
    selectPhoto(photoIndex) {
      this.selectedIndex = photoIndex;
    },
    transView(view) {
      this.currentView = view;
    },
    currentViewIs(view) {
      return this.currentView === view;
    },
    makeDetectionsUrl(photo) {
      if (photo.key !== 'tab') {
        return `/api/v2/events/${this.event.id}/insect_detections/${photo.key}`;
      }
      return null;
    },
    // public
    resetView() {
      this.transView('table');
    },
  },
  filters: {
    dateTime(value, format) {
      return moment(value * 1000).format(format);
    },
    imageSrc(photo) {
      if (photo.key === 'tab') {
        return photo.original;
      }
      return photo.display;
    },
  },
  watch: {
    event: function (value, prev) {
      if (value.id !== prev.id) {
        this.selectedIndex = 1;
        this.pickedDetectionType = 'all';
        this.resetZoom();
      }
    },
    'event.updating': function (value, prev) {
      if (prev && !value) {
        this.$refs.drawers.forEach((drawer) => {
          drawer.refetch();
        });
      }
    },
    selectedIndex() {
      this.resetZoom();
    },
  },
};
</script>

<style scoped lang="sass">
.station-event
  &__metadata
    font-size: 80%
    ul
      padding-left: 0px
      list-style: none

  &__drawer
    height: 65vh
    display: flex
    max-width: 100%
    overflow-x: auto
    overflow-y: hidden

  &__selector
    display: flex
    justify-content: center
    margin-top: 0.5rem

  &__main
    flex-grow: 1
    max-width: calc(100% - 450px)
    margin-right: 10px

    // To trigger resize event
    .v-window-item.active
      padding-right: 1px

  &__side
    width: 450px
    min-width: 450px
    display: flex
    flex-direction: column

  &__table-area
    min-height: 500px
    height: 65vh
    display: flex
    flex-direction: column
    border: 2px solid #ddd

  &__edit-button
    margin: 0 0 0 auto

    .v-btn
      margin: 0
      height: auto
      min-width: 64px

    ::v-deep .v-btn__content
      height: auto
      flex-direction: column

    .v-icon
      color: #0068b6

    small
      line-height: 2em

  &__content
    border: 2px solid #ccc
    background-color: #fff

  .loading-image, .picked-insects-draw
    height: 100%
    min-height: 100px
    width: 100%
    object-fit: contain

  &__result-table
    overflow: hidden
    flex-grow: 1

    ::v-deep .v-data-table
      height: 100%

      &__wrapper
        height: 100%
        overflow-y: auto

        td
          background-color: #fff

  ::v-deep .dot-selector
    &__item:first-child::before
      content: '\f02b'
      font: normal normal normal 14px/1 FontAwesome

.details
  padding-left: 3rem
  padding-right: 3rem

::v-deep .v-window__prev,
::v-deep .v-window__next
  z-index: 12

.col
  padding: 0
</style>
